import React from "react"
import Main from "./components/pages/main/Main";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PrivacyPolicy} from "./components/pages/privacy-policy/PrivacyPolicy";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path ="/privacy-policy" element={<PrivacyPolicy/>} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
